import { render, staticRenderFns } from "./Block6.vue?vue&type=template&id=86b058dc&scoped=true&"
import script from "./Block6.vue?vue&type=script&lang=js&"
export * from "./Block6.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86b058dc",
  null
  
)

export default component.exports