<template>
  <div v-if="rowID !== '0'">
    <div v-if="loading" style="min-height: 200px;" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div class="blocks" v-else>
      <div class="blocks__headerDiv" style="display: flex; flex-direction: row"
           :style="!blockOpened ? 'border-bottom: none' : ''" @click="blockOpened = !blockOpened">
        <p class="headerText">Блок 1. Информация о научных публикациях</p>
        <img
          v-if="blockOpened"
          src="@/assets/icons/block_opened_blue.svg" class="btn_open" alt="">
        <img
          v-else
          src="@/assets/icons/block_closed_blue.svg" class="btn_open" alt="">
      </div>
      <div v-if="blockOpened && block1Data.length > 0">
        <Block1Item
          v-for="(item, j) in block1Data" :key="j"
          :value.sync="item"
          :rowID="rowID"
          :specID="specID"
          :konvertConvert="konvertConvert"
          :order="j"
          :applicantBlocked="applicantBlocked"
          :articlePubTypeArray="articlePubTypeArray"
          :articlePubCategoryArray="articlePubCategoryArray"
          @updateData="getAllBlock1"
        />
      </div>
      <Block1Creation
        v-if="blockOpened && blockEditingInProcess && !applicantBlocked"
        :rowID="rowID"
        :applicantBlocked="applicantBlocked"
        :specID="specID"
        @updateData="getAllBlock1"
      />
      <div class="btnDiv" v-if="blockOpened && block1Data.length > 0">
        <button v-if="applicantBlocked || isExpert" class="usual-btn usual-btn_disabled">Добавить</button>
        <button v-else class="usual-btn" @click="addRow">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Block1Item from "@/components/applicants/applicantForm/mainBlocks/blockItems/Block1Item";
import Block1Creation from "@/components/applicants/applicantForm/mainBlocks/blockCreation/Block1Creation";

export default {
  name: "Block1",
  components: {Block1Creation, Block1Item},
  data() {
    return {
      loading: false,
      blockOpened: false,
      blockEditingInProcess: true,
      block1Data: [],
      articlePubTypeArray: [],
      articlePubCategoryArray: [],
    }
  },
  props: {//specID
    rowID: {
      type: String,
      default: ''
    },
    specID: {
      type: Number,
      default: 0
    },
    applicantBlocked: {
      type: Boolean,
      default: false,
    },
    konvertConvert: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isExpert: 'isExpert',
      // isCurator: 'isCurator',
    }),
  },
  created() {
    if (this.rowID !== '0') {
      this.getAllBlock1();
      this.getSprPubType();
      this.getSprPubCategory();
    }
    
    if (this.isExpert) {
      this.blockOpened = true;
    }
  },
  methods: {
    async getAllBlock1() {
      this.loading = true;
      this.block1Data = [];
      try {
        const res = await axios.get(`/api/2022/main/block1/get?mainId=${this.rowID}`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          if (res.data.length > 0) {
            this.block1Data = res.data;
            this.blockEditingInProcess = false;
          } else {
            this.blockEditingInProcess = true;
          }
          console.log('getAllBock1 успех');
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    
    async getSprPubType() {
      try {
        const res = await axios.get(`/api/2022/spr/textVal/get?block=1&position=1`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubTypeArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprPubType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getSprPubCategory() {
      try {
        const res = await axios.get(`/api/2022/spr/textVal/get?block=1&position=2`, {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}})
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.articlePubCategoryArray.push({
              text: res.data[i].value,
              value: res.data[i].id
            })
          }
          console.log('getSprPubCategory успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    addRow() {
      this.blockEditingInProcess = true
    },
  }
}
</script>

<style lang="scss">

</style>